import { Client, ClientShort, Language } from "@/lib/types";
import { setCookie } from "cookies-next";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { createContext, useState, useEffect } from "react";

export type AppContextType = {
  isOldVersion: boolean;
  clientsList: ClientShort[];
  currentClient: Client | null;
  clientDefault: Client | null;
  languages: Language[];
  assetsUrl: string;
  setIsOldVersion: (isOldVersion: boolean) => void;
  setClientsList: (list: ClientShort[]) => void;
  setCurrentClient: (client: Client) => void;
  setClientDefault: (clientDefault: Client) => void;
  setLanguages: (languages: Language[]) => void;
  changeAppLanguage: (abbreviation: Language["abbreviation"]) => void;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

export function AppContextProvider({ children }: { children: React.ReactNode }) {
  const { i18n } = useTranslation();
  const router = useRouter();
  const [clientsList, setClientsList] = useState<ClientShort[]>([]);
  const [currentClient, setCurrentClient] = useState<Client | null>(null);
  const [clientDefault, setClientDefault] = useState<Client | null>(null);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [isOldVersion, setIsOldVersion] = useState<boolean>(false);

  useEffect(() => {
    if ("undefined" !== typeof window) {
      document.documentElement.classList.toggle("old-version", isOldVersion);
    }
  }, [isOldVersion]);

  const setInitialLanguages = (languages: Language[]): void => {
    setLanguages(languages);
  };

  const changeAppLanguage = async (abbreviation: Language["abbreviation"]) => {
    if (!languages) return;
    setCookie("NEXT_LOCALE", abbreviation);
    await i18n.changeLanguage(abbreviation);
    await router.push(
      {
        pathname: router.pathname,
        query: router.query,
      },
      router.asPath,
      {
        locale: abbreviation,
        scroll: false,
        shallow: true,
      }
    );
  };

  return (
    <AppContext.Provider
      value={{
        isOldVersion,
        setIsOldVersion,
        assetsUrl: "",
        clientsList,
        setClientsList,
        currentClient,
        setCurrentClient,
        clientDefault,
        setClientDefault,
        languages,
        setLanguages: setInitialLanguages,
        changeAppLanguage,
      }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppContextProvider;
