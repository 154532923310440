const HttpBackend = require("i18next-http-backend/cjs");

const isDev = process.env.NEXT_PUBLIC_APP_MODE === "development";

module.exports = {
  debug: false,
  backend: {
    backendOptions: [{ expirationTime: isDev ? 0 : 60 * 60 * 1000 }, {}], // 1 hour
    loadPath: `${process.env.NEXT_PUBLIC_CDN_BASE_URL}/locale/{{lng}}.json`,
    backends: [HttpBackend],
  },
  partialBundledLanguages: true,
  // react: { // used only for the lazy reload
  //   bindI18n: 'languageChanged loaded',
  //   useSuspense: false
  // },
  i18n: {
    defaultLocale: "en",
    locales: ["en"],
  },
  use: [HttpBackend],
  serializeConfig: false,
};
