import AppContextProvider from "@/context/AppContextProvider";
import "@/styles/global.css";
import "@/styles/old-version/old-version-styles.scss";
import { appWithTranslation } from "next-i18next";
import React from "react";

import nextI18nConfig from "../../next-i18next.config";

function MyApp({ Component, pageProps }: { Component: any; pageProps: any }) {
  return (
    <AppContextProvider>
      <Component {...pageProps} className="animate-fade-in" />
    </AppContextProvider>
  );
}

export default appWithTranslation(MyApp, nextI18nConfig);
